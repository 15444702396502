.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.login-center {
  box-shadow: var(--box-shadow-s);
  border-radius: 10px;
  padding: 40px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.login-page h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.login-center h2 {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 100 !important;
  color: var(--pri-color-light);
}

.login-center button {
  margin-left: auto;
  border-radius: 40px;
  box-shadow: var(--box-shadow-s);
  font-weight: 600;
  font-size: 18px;
}

.fail-text {
  position: fixed;
  top: 50px;
  left: 50vw;
  transform: translateX(-50%);
}

.login-center p {
  margin-top: 30px;
}

.username-input {
  margin-bottom: 30px !important;
}

.login-center .row {
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.login-center .row p {
  margin: 0;
  max-width: 200px;
}
