.setup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.setup-page h1 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.setup-center {
  margin-top: 40px;
  box-shadow: var(--box-shadow-s);
  border-radius: 10px;
  padding: 40px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.create-account-button {
  border-radius: 40px;
  margin-left: auto;
  margin-top: 30px;
}

.setup-center h2 {
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 18px;
}

.enter-pass1 {
  margin-bottom: 30px !important;
}
.enter-pass2 {
  margin-bottom: 40px !important;
}

.setup-center p {
  border-right: none !important;
}

.invalid-username {
  margin-bottom: 5px;
  text-align: end;
  width: 100%;
  color: var(--contrast-color);
  margin-right: 10px;
  font-weight: 400;
}

.valid-username {
  margin-bottom: 5px;
  text-align: end;
  width: 100%;
  color: var(--green-color);
  margin-right: 10px;
  font-weight: 400;
}

.enter-username {
  margin-bottom: 30px !important;
}
.enter-phone {
  margin-bottom: 40px !important;
}

.enter-username-valid .MuiOutlinedInput-notchedOutline,
.enter-pass1-valid .MuiOutlinedInput-notchedOutline,
.enter-pass2-valid .MuiOutlinedInput-notchedOutline,
.enter-phone-valid .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--green-color) !important;
}
.enter-username-valid .MuiInputLabel-root,
.enter-pass1-valid .MuiInputLabel-root,
.enter-pass2-valid .MuiInputLabel-root,
.enter-phone-valid .MuiInputLabel-root {
  color: var(--green-color) !important;
}

.enter-username-invalid .MuiOutlinedInput-notchedOutline,
.enter-pass1-invalid .MuiOutlinedInput-notchedOutline,
.enter-pass2-invalid .MuiOutlinedInput-notchedOutline,
.enter-phone-invalid .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--contrast-color) !important;
}
.enter-username-invalid .MuiInputLabel-root,
.enter-pass1-invalid .MuiInputLabel-root,
.enter-pass2-invalid .MuiInputLabel-root,
.enter-phone-invalid .MuiInputLabel-root {
  color: var(--contrast-color) !important;
}

.pass-strength {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
  width: 100%;
}

.pass-strength-on {
  width: 25%;
  height: 3px;
  background: var(--contrast-color);
}

.pass-strength-off {
  width: 25%;
  height: 3px;
  background: var(--bor-color);
}

.invite-expiration {
  position: absolute;
  top: 45%;
  font-weight: 400;
  color: var(--pri-color-light);
  font-size: 18px;
}

.confirm-text {
  margin-bottom: 200px;
}

.otp-sender {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.otp-sender .enter-username {
  margin-bottom: 0px !important;
  margin-right: auto !important;
}

.otp-sender button {
  border-radius: 100%;
  width: 50px;
  padding: 0;
  height: 50px;
}

.otp-sender button .icon {
  stroke: white !important;
  width: 20px;
  height: 20px;
}
