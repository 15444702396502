.download-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 110px);
}

.download-center h1 {
    margin-bottom: 10px;
}

.download {
    border: 1px solid var(--bor-color);
    border-radius: 15px;
    padding: 30px;
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;
    width: 50vw;
    max-width: 600px;
    overflow: hidden;
}

.download:hover {
    transform: scale(1.01);
    outline: 2px solid var(--sec-color);
}

.download .icon {
    margin-right: 20px;
    min-width: 26px;
    min-height: 26px;
    max-width: 26px;
    max-height: 26px;
}

.download h2 {
    margin-bottom: 3px;
}
.download-center a {
    margin-top: 20px;
    margin-bottom: 40px;
}