.eula-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.eula-page h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.eula-page-center {
  box-shadow: var(--box-shadow-s);
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  position: relative;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.eula-page-center .row {
  display: flex;
  align-items: center;
}

.eula-page-center .company-logo {
  width: 36px;
  margin: 0;
}

.eula-page-center .row h2 {
  margin-left: 10px;
  font-weight: 600;
  font-size: 18px;
}

.eula-content {
  overflow-y: scroll;
  margin-top: 20px;
  max-height: 300px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  margin-bottom: auto;
}

.eula-content h1 {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 20px;
  margin-top: 0px;
}

.eula-content p {
  font-weight: 13px;
  line-height: 1.4;
  color: var(--pri-color);
}

.download-eula {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.download-eula:hover h3,
.download-eula:hover .icon {
  color: var(--sec-color);
  stroke: var(--sec-color);
}

.download-eula h3 {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--pri-color-light);
}

.please-read {
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
  color: var(--pri-color-light);
}

.eula-disagree {
  margin-left: auto;
  margin-right: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
  color: var(--pri-color-light);
  background: transparent;
  border: 1px solid var(--bor-color);
}

.eula-agree {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
}

.eula-page-center .row {
    height: 60px;
}