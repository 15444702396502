.upload-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.upload-center {
  min-height: 500px;
  max-height: 500px;
  width: 100vw;
  max-width: 800px;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  box-shadow: var(--box-shadow-s);
  margin-bottom: 40px;
}

.upload-info {
  display: flex;
  flex-direction: column;
  min-height: calc(500px - 80px);
  max-height: calc(500px - 80px);
  width: calc(50% - 80px);
  max-width: 400px;
  padding: 40px;
  border-right: 2px solid var(--sec-bg-color);
}

.upload-info p:nth-of-type(1) {
  margin-top: 20px;
}

.upload-info p:nth-of-type(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-files {
  min-height: calc(500px - 80px);
  max-height: calc(500px - 80px);
  width: calc(50% - 80px);
  max-width: 400px;
  padding: 40px;
  display: flex;
  flex-direction: column;

}

.upload-center h1 {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 500;
}

.upload-center h3 {
  font-size: 16px;
  margin-top: 20px;
  color: var(--pri-color-light);
  font-weight: 500;
  margin-bottom: 10px;
}

.upload-button {
  margin-top: auto;
  border-radius: 40px;
}

.upload-button-off {
  margin-top: auto;
  border-radius: 40px;
  background: transparent;
  pointer-events: none;
  color: var(--pri-color-light);
  outline: 1px solid var(--sec-bg-color);
  font-weight: 400;
}

.upload-dropzone {
  height: 300px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
  border-radius: 5px;
  overflow-y: scroll;
}

.upload-dropzone button {
  margin-bottom: 20px;
  margin-top: 30px;
  border-radius: 40px;
  background: transparent;
  border: 1px solid var(--bor-color);
  color: var(--pri-color-light);
  font-weight: 500;
  font-size: 16px !important; 
}

.upload-files a {
  margin: auto;
  margin-bottom: 0;
  align-self: center;
}


.upload-dropzone p {
  text-align: center;
  line-height: 1.4;
}

.file-to-upload {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.file-to-upload p {
  color: var(--pri-color);
  max-width: 80%;
  overflow: hidden;
  word-break: none;
  white-space: pre;
  text-overflow: ellipsis;
}

.file-to-upload .icon {
  cursor: pointer;
  border-radius: 100%;
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  padding: 5px;
  width: 14px;
  height: 14px;
  margin-left: auto;
}

.upload-dropzone h2 {
  text-align: center;
}