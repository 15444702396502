.admin-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 160px);
  padding-bottom: 50px;
  font-family: monospace !important;
}

.admin-center h2 {
  text-align: left;
  align-self: flex-start;
  margin-top: 50px;
}

.admin-assets {
  width: 100%;
  border: 1px solid var(--bor-color);
  max-height: 300px;
  margin-top: 30px;
  border-radius: 15px;
  overflow-y: scroll;
}

.admin-asset {
  padding: 30px;
  border-bottom: 1px solid var(--bor-color);
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
}

.admin-asset:hover {
  background: var(--sec-bg-color);
}

.admin-asset h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.a-a-row {
  margin-top: 10px;
  padding-left: 20px;
}

.upload-assets-button {
  margin-top: 20px;
  margin-left: auto;
  border-radius: 40px;
  font-size: 15px !important;
}

.managed-user {
  padding: 30px;
  border-bottom: 1px solid var(--bor-color);
  display: flex;
  align-items: center;
}

.managed-user .row {
  margin-left: auto;
}

.managed-user .icon {
  border: 1px solid var(--bor-color);
  margin-left: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.managed-user .icon:hover {
  background: var(--sec-bg-color);
}

.managed-user h3 {
  line-height: 1.4;
  font-size: 14px;
  font-weight: 400;
}

.file-upload-load-displayer {
}

/* NewUserRegister */

.new-user-register {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

/* AssetAttacher */

.asset-attacher {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.asset-attacher-assets {
  border-radius: 20px;
  background: var(--bg-color);
  border: 1px solid var(--bor-color);
  width: 600px;
  max-height: 500px;
  padding-top: 0px;
  overflow-y: scroll;
  position: relative;
}

.asset-attacher-assets .row {
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 40px);
  justify-content: flex-end !important;
  padding: 20px;
  gap: 10px;
  display: flex;
  background-color: var(--sec-bg-color);
}

.asset-attacher-assets .row button {
  font-size: 14px !important;
}

.asset-toggle {
  display: flex;
  align-items: center;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--sec-bg-color);
}

.asset-toggle h3 {
  margin-right: auto;
}

.inline-asset {
  font-size: 14px !important;
  line-height: 1.4;
}
