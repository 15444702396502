:root {
  --bg-color: #15202b;
  --sec-bg-color: #192734;
  --pri-color: #ffffff;
  --pri-color-light: rgb(136, 153, 166);
  --bor-color: #38444d;
  --sec-color: #4287f5;
  --contrast-color: #eb4034;
  --yellow-color: #fcba03;
  --green-color: #32a852;
  --box-shadow-s: 0 2px 4px rgb(0 0 0 / 7%), 0 4px 5px rgb(0 0 0 / 6%),
    0 1px 10px rgb(0 0 0 / 10%);
  --box-shadow-l: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg-color);
  color: var(--pri-color);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

button {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px !important;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 16px;
  font-weight: 500;
}

p {
  color: var(--pri-color-light);
  font-size: 15px;
}

input {
  background: none;
  border: 1px solid var(--bor-color);
  border-radius: 10px;
  height: 24px;
  color: var(--pri-color);
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
}

input:focus {
  outline: 1px solid var(--sec-color);
}

button {
  cursor: pointer;
  background: var(--sec-color);
  border: none;
  color: var(--pri-color);
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
  transition: 0.2s;
}

button:hover {
  transform: scale(1.01);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--pri-color-light);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--pri-color-light);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--pri-color-light);
}

.icon {
  stroke: var(--pri-color-light);
}

a {
  font-size: 14px;
  color: var(--pri-color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a .icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.lds-dual-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid var(--sec-color);
  border-color: var(--sec-color) transparent var(--sec-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
